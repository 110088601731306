<template>
    <div class="w-full h-full">
        <div class="flex flex-col w-full gap-4 p-3 bg-card-bg rounded-md shadow">
            <div
                class="flex justify-end items-center w-full"
                v-if="!toggle.createCaseRisk && !viewMode && checkPermission('riskAssessment.edit')"
            >
                <div class="">
                    <v-button
                        :text="$t('risk_assessment.create_risk_label')"
                        class="py-2"
                        type="primary"
                        :disabled="viewMode || isEdit"
                        @click="handleCreateCase('Case')"
                    />
                </div>
            </div>
            <div class="flex max-w-full flex-col" v-if="toggle.createCaseRisk">
                <CreateCaseRisk
                    @close="closeCreateCaseRisk('Case')"
                    @submitForm="submitStatus"
                    :colorOptions="colorOptions"
                    :riskAssessmentId="getRiskAssessmentId"
                    :status="riskCaseLabelDetail"
                    :mode="this.caseMode"
                    :riskCaseLabelList="this.riskCaseLabelList"
                />
            </div>
            <div class="flex flex-col max-w-full gap-4" v-if="riskCaseLabelList.length">
                <list-header :headers="computedHeaders" :viewOnlyMode="viewOnlyMode"/>
                <v-draggable
                    v-if="!listLoading && riskCaseLabelList.length"
                    class="list-group flex flex-col gap-2 transition-all duration-200 overflow-auto scroll-bar"
                    style="max-height: 50vh"
                    :list="riskCaseLabelList"
                    :sort="true"
                    v-bind="dragOptions"
                >
                    <transition-group type="transition" name="flip-list" class="flex flex-col space-y-2">
                        <div v-for="(el, index) in riskCaseLabelList" :key="index">
                            <div class="flex w-full h-8 rounded-lg justify-center items-center">
                                <div class="grid w-full items-center p-1 bg-gray-100 rounded-md border-2"
                                    :class="{'border-blue-500' : selectedRowId === el.name}"
                                >
                                    <div class="flex gap-4 w-full text-sm">
                                        <div class="flex w-4/12 px-2 truncate" >
                                            <div class="flex items-center gap-1">
                                                <div
                                                    class="rounded-full h-4 w-4"
                                                    :style="`background-color: ${el.color}`"
                                                ></div>
                                                <span class="" :title=" el.name">{{ el.name }}</span>
                                            </div>
                                        </div>
                                        <div class="flex w-4/12 truncate "  v-if="!viewOnlyMode" :title=" el.description">
                                            {{ el.description || '' }}
                                        </div>
                                        <div class="flex w-3/12 truncate px-1.5"  v-if="!viewOnlyMode">
                                            {{ el.min_label_score }} - {{el.max_label_score}}
                                        </div>
                                        <div class="truncate flex items-center text-primary justify-center w-1/12"  v-if="!viewOnlyMode">
                                            <span
                                                class="px-2 ml-3"
                                                :class="viewMode || isEdit ? 'cursor-not-allowed pointer-events-none' : ' cursor-pointer'"
                                                @click="editStatus(el)"
                                                
                                            >
                                                <svg width="12" height="12" viewBox="0 0 12 12" 
                                                    :fill="viewMode || isEdit ? '#6b7280' : 'currentColor'"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clip-path="url(#clip0_368_32080)">
                                                        <path
                                                            d="M11.0531 0.508618C10.5398 -0.00466309 9.71016 -0.00466309 9.19687 0.508618L8.49141 1.21174L10.7859 3.50627L11.4914 2.80081C12.0047 2.28752 12.0047 1.45784 11.4914 0.944556L11.0531 0.508618ZM4.04062 5.66487C3.89766 5.80784 3.7875 5.98362 3.72422 6.17815L3.03047 8.2594C2.9625 8.46096 3.01641 8.68362 3.16641 8.83596C3.31641 8.98831 3.53906 9.03987 3.74297 8.9719L5.82422 8.27815C6.01641 8.21487 6.19219 8.10471 6.3375 7.96174L10.2586 4.03831L7.96172 1.74143L4.04062 5.66487ZM2.25 1.50002C1.00781 1.50002 0 2.50784 0 3.75002V9.75002C0 10.9922 1.00781 12 2.25 12H8.25C9.49219 12 10.5 10.9922 10.5 9.75002V7.50002C10.5 7.08518 10.1648 6.75002 9.75 6.75002C9.33516 6.75002 9 7.08518 9 7.50002V9.75002C9 10.1649 8.66484 10.5 8.25 10.5H2.25C1.83516 10.5 1.5 10.1649 1.5 9.75002V3.75002C1.5 3.33518 1.83516 3.00002 2.25 3.00002H4.5C4.91484 3.00002 5.25 2.66487 5.25 2.25002C5.25 1.83518 4.91484 1.50002 4.5 1.50002H2.25Z"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_368_32080">
                                                            <rect width="12" height="12" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span
                                                class="px-2 ml-3"
                                                :class="
                                                viewMode || isEdit
                                                    ? 'cursor-not-allowed pointer-events-none'
                                                    : ' cursor-pointer'
                                                "
                                                @click="deleteRiskLable(el)"
                                            >
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    :fill="viewMode || isEdit ? '#6b7280' : '#FF0000'"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </v-draggable>
                <div v-else-if="listLoading" class="flex justify-center items-start">
                    <Loader />
                </div>
                <!-- <recursive-status-list :status-list="statusList" workflowType="globalCase"/> -->
            </div>
            <modal-confirm 
                :title="$t('risk_assessment.confirm_modal_title')" 
                :message="$t('risk_assessment.delete_field_confirmation')" 
                ref="confirm-popup"
            />
        </div>
        <div class="flex-1 relative bg-card-bg rounded-md shadow mt-4">
            <div :style="`max-height: 20vh; border-radius: 0.375rem 0.375rem 0 0;`"
                class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium"
            >
                <span class="font-bold"> {{ $t('risk_assessment.risk_label__entity') }} </span>
            </div>
            <div class="p-3 flex pb-4">
                <div class="flex flex-col w-full h-full gap-4">
                    <div
                        class="flex justify-end items-center w-full"
                        v-if="!toggle.createEntityRisk && !viewMode && checkPermission('riskAssessment.edit')"
                    >
                        <div class="">
                            <v-button
                                :text="$t('risk_assessment.create_risk_label')"
                                class="py-2"
                                type="primary"
                                :disabled="viewMode || isEdit"
                                @click="handleCreateCase('Entity')"
                            />
                        </div>
                    </div>
                    <div class="flex max-w-full flex-col" v-if="toggle.createEntityRisk">
                        <create-risk
                            @close="closeCreateCaseRisk('Entity')"
                            @submitForm="submitStatus"
                            :colorOptions="colorOptions"
                            :riskAssessmentId="getRiskAssessmentId"
                            :status="riskEntityLabelDetail"
                            :mode="this.entityMode"
                            :riskEntityLabelList="this.riskEntityLabelList"
                        />
                    </div>
                    <div class="flex flex-col max-w-full gap-4" v-if="riskEntityLabelList.length">
                        <list-header :headers="computedHeaders" :viewOnlyMode="viewOnlyMode"/>
                        <v-draggable
                            v-if="!listLoading && riskEntityLabelList.length"
                            class="list-group flex flex-col gap-2 transition-all duration-200 overflow-auto scroll-bar"
                            style="max-height: 50vh"
                            :list="riskEntityLabelList"
                            :sort="true"
                            v-bind="dragOptions"
                        >
                            <transition-group type="transition" name="flip-list" class="flex flex-col space-y-2">
                                <div v-for="(el, index) in riskEntityLabelList" :key="index">
                                    <div class="flex w-full h-8 rounded-lg justify-center items-center">
                                        <div class="grid w-full bg-gray-100 items-center p-1 rounded-md border-2"
                                            :class="{'border-blue-500' : selectedRowId === el.name}"
                                        >
                                            <div class="flex gap-4 w-full text-sm">
                                                <div class="flex w-4/12 px-2 truncate" >
                                                    <div class="flex items-center gap-1">
                                                        <div
                                                            class="rounded-full h-4 w-4"
                                                            :style="`background-color: ${el.color}`"
                                                        ></div>
                                                        <span class="" :title=" el.name">{{ el.name }}</span>
                                                    </div>
                                                </div>
                                                <div class="flex w-4/12 truncate "  v-if="!viewOnlyMode" :title=" el.description">
                                                    {{ el.description || '' }}
                                                </div>
                                                <div class="flex w-3/12 truncate px-1.5"  v-if="!viewOnlyMode">
                                                    {{ el.min_label_score }} - {{el.max_label_score}}                                                
                                                </div>
                                                <div class="truncate flex items-center text-primary justify-center w-1/12"  v-if="!viewOnlyMode">
                                                    <span
                                                        class="px-2 ml-3"
                                                        :class="viewMode || isEdit ? 'cursor-not-allowed pointer-events-none' : ' cursor-pointer'"
                                                        @click="editStatus(el)"
                                                    >
                                                        <svg
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 12 12"
                                                            :fill="viewMode || isEdit ? '#6b7280' : 'currentColor'"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g clip-path="url(#clip0_368_32080)">
                                                                <path
                                                                    d="M11.0531 0.508618C10.5398 -0.00466309 9.71016 -0.00466309 9.19687 0.508618L8.49141 1.21174L10.7859 3.50627L11.4914 2.80081C12.0047 2.28752 12.0047 1.45784 11.4914 0.944556L11.0531 0.508618ZM4.04062 5.66487C3.89766 5.80784 3.7875 5.98362 3.72422 6.17815L3.03047 8.2594C2.9625 8.46096 3.01641 8.68362 3.16641 8.83596C3.31641 8.98831 3.53906 9.03987 3.74297 8.9719L5.82422 8.27815C6.01641 8.21487 6.19219 8.10471 6.3375 7.96174L10.2586 4.03831L7.96172 1.74143L4.04062 5.66487ZM2.25 1.50002C1.00781 1.50002 0 2.50784 0 3.75002V9.75002C0 10.9922 1.00781 12 2.25 12H8.25C9.49219 12 10.5 10.9922 10.5 9.75002V7.50002C10.5 7.08518 10.1648 6.75002 9.75 6.75002C9.33516 6.75002 9 7.08518 9 7.50002V9.75002C9 10.1649 8.66484 10.5 8.25 10.5H2.25C1.83516 10.5 1.5 10.1649 1.5 9.75002V3.75002C1.5 3.33518 1.83516 3.00002 2.25 3.00002H4.5C4.91484 3.00002 5.25 2.66487 5.25 2.25002C5.25 1.83518 4.91484 1.50002 4.5 1.50002H2.25Z"
                                                                />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_368_32080">
                                                                    <rect width="12" height="12" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                    <span
                                                        class="px-2 ml-3"
                                                        :class="
                                                        viewMode || isEdit
                                                            ? 'cursor-not-allowed pointer-events-none'
                                                            : ' cursor-pointer'
                                                        "
                                                        @click="deleteRiskLable(el)"
                                                    >
                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                            :fill="viewMode || isEdit ? '#6b7280' : '#FF0000'"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </v-draggable>
                        <div v-else-if="listLoading" class="flex justify-center items-start">
                            <Loader />
                        </div>
                        <!-- <recursive-status-list :status-list="statusList" workflowType="globalCase"/> -->
                    </div>
                    <modal-confirm 
                        :title="$t('risk_assessment.confirm_modal_title')" 
                        :message="$t('risk_assessment.delete_field_confirmation')" 
                        ref="confirm-popup"
                    />
                </div>
            </div>
        </div>
        <div class="flex-1 relative bg-card-bg rounded-md shadow mt-4">
            <div :style="`max-height: 20vh; border-radius: 0.375rem 0.375rem 0 0;`"
                class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium"
            >
                <span class="font-bold"> {{ $t('risk_assessment.risk_label__category') }} </span>
            </div>
            <div class="p-3 flex pb-4">
                <div class="flex flex-col w-full h-full gap-4">
                    <div
                        class="flex justify-end items-center w-full"
                        v-if="!toggle.createCategortRisk && !viewMode && checkPermission('riskAssessment.edit')"
                    >
                        <div class="">
                            <v-button
                                :text="$t('risk_assessment.create_risk_label')"
                                class="py-2"
                                type="primary"
                                :disabled="viewMode || isEdit"
                                @click="handleCreateCase('risk_category')"
                            />
                        </div>
                    </div>
                    <div class="flex max-w-full flex-col" v-if="toggle.createCategortRisk">
                        <CreateCategoryRisk
                            @close="closeCreateCaseRisk('risk_category')"
                            @submitForm="submitStatus"
                            :colorOptions="colorOptions"
                            :riskAssessmentId="getRiskAssessmentId"
                            :status="riskCategoryLabelDetail"
                            :mode="this.categoryMode"
                            :riskCategoryLabelList=this.riskCategoryLabelList
                        />
                    </div>
                    <div class="flex flex-col max-w-full gap-4" v-if = "riskCategoryLabelList.length">
                        <list-header :headers="computedHeaders" :viewOnlyMode="viewOnlyMode"/>
                        <v-draggable
                            v-if="!listLoading && riskCategoryLabelList.length"
                            class="list-group flex flex-col gap-2 transition-all duration-200 overflow-auto scroll-bar"
                            style="max-height: 50vh"
                            :list="riskCategoryLabelList"
                            :sort="true"
                            v-bind="dragOptions"
                        >
                            <transition-group
                                type="transition"
                                name="flip-list"
                                class="flex flex-col space-y-2"
                            >
                                <div v-for="(el, index) in riskCategoryLabelList" :key="index">
                                    <div class="flex w-full h-8 rounded-lg justify-center items-center">
                                        <div class="grid w-full bg-gray-100 items-center p-1 rounded-md border-2"
                                            :class="{'border-blue-500' : selectedRowId === el.name}"
                                        >
                                            <div class="flex gap-4 w-full text-sm">
                                                <div class="flex w-4/12 px-2 truncate" >
                                                    <div class="flex items-center gap-1">
                                                        <div
                                                            class="rounded-full h-4 w-4"
                                                            :style="`background-color: ${el.color}`"
                                                        ></div>
                                                        <span class="" :title=" el.name">{{ el.name }}</span>
                                                    </div>
                                                </div>
                                                <div class="flex w-4/12 truncate "  v-if="!viewOnlyMode" :title=" el.description">
                                                    {{ el.description || '' }}
                                                </div>
                                                <div class="flex w-3/12 truncate px-1.5"  v-if="!viewOnlyMode">
                                                    {{ el.min_label_score }} - {{el.max_label_score}}
                                                </div>
                                                <div class="truncate flex items-center text-primary justify-center w-1/12"  v-if="!viewOnlyMode">
                                                    <span
                                                        class="px-2 ml-3"
                                                        :class="viewMode || isEdit ? 'cursor-not-allowed pointer-events-none' : ' cursor-pointer'"
                                                        @click="editStatus(el)"
                                                    >
                                                        <svg
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 12 12"
                                                            :fill="viewMode || isEdit ? '#6b7280' : 'currentColor'"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            
                                                        >
                                                            <g clip-path="url(#clip0_368_32080)">
                                                                <path
                                                                    d="M11.0531 0.508618C10.5398 -0.00466309 9.71016 -0.00466309 9.19687 0.508618L8.49141 1.21174L10.7859 3.50627L11.4914 2.80081C12.0047 2.28752 12.0047 1.45784 11.4914 0.944556L11.0531 0.508618ZM4.04062 5.66487C3.89766 5.80784 3.7875 5.98362 3.72422 6.17815L3.03047 8.2594C2.9625 8.46096 3.01641 8.68362 3.16641 8.83596C3.31641 8.98831 3.53906 9.03987 3.74297 8.9719L5.82422 8.27815C6.01641 8.21487 6.19219 8.10471 6.3375 7.96174L10.2586 4.03831L7.96172 1.74143L4.04062 5.66487ZM2.25 1.50002C1.00781 1.50002 0 2.50784 0 3.75002V9.75002C0 10.9922 1.00781 12 2.25 12H8.25C9.49219 12 10.5 10.9922 10.5 9.75002V7.50002C10.5 7.08518 10.1648 6.75002 9.75 6.75002C9.33516 6.75002 9 7.08518 9 7.50002V9.75002C9 10.1649 8.66484 10.5 8.25 10.5H2.25C1.83516 10.5 1.5 10.1649 1.5 9.75002V3.75002C1.5 3.33518 1.83516 3.00002 2.25 3.00002H4.5C4.91484 3.00002 5.25 2.66487 5.25 2.25002C5.25 1.83518 4.91484 1.50002 4.5 1.50002H2.25Z"
                                                                />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_368_32080">
                                                                    <rect width="12" height="12" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                    <span
                                                        class="px-2 ml-3"
                                                        :class="
                                                        viewMode || isEdit
                                                            ? 'cursor-not-allowed pointer-events-none'
                                                            : ' cursor-pointer'
                                                        "
                                                        @click="deleteRiskLable(el)"
                                                    >
                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                            :fill="viewMode || isEdit ? '#6b7280' : '#FF0000'"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </v-draggable>
                        <div v-else-if="listLoading" class="flex justify-center items-start">
                            <Loader />
                        </div>
                        <!-- <recursive-status-list :status-list="statusList" workflowType="globalCase"/> -->
                    </div>
                    <modal-confirm 
                        :title="$t('risk_assessment.confirm_modal_title')" 
                        :message="$t('risk_assessment.delete_field_confirmation')" 
                        ref="confirm-popup"
                    />
                </div>
            </div>
        </div>
        <div class="flex justify-end py-4 pr-4 bg-card-bg mt-4 rounded-md shadow" v-if="!viewMode">
            <div class="">
                <v-button
                    :text="$t('actions.done')"
                    class="py-2"
                    type="primary"
                    :disabled="getDisableState"
                    @click="handleSubmit"
                />
            </div>
        </div>
        
    </div>
</template>
  
  <script>
  import Button from "@shared/components/button";
  import CreateRisk from "./create-entity-risk.vue";
  import CreateCaseRisk from "./create-case-risk.vue";
  import CreateCategoryRisk from "./create-category-risk.vue";
  import ListHeader from "./risk-list-header.vue";
  import draggable from "vuedraggable";
  import { createRiskLabel, getRiskLabel, updateRiskLabel, deleteRiskLabel } from "../service";
  import Loader from "@shared/loader";
  const modalConfirm = () => import("../../../components/modal-confirm");
  import { checkPermission } from "@shared/utils/functions"

  
  export default {
    name: "label",
    components: {
      "v-button": Button,
      "list-header": ListHeader,
      "v-draggable": draggable,
      CreateRisk,
      CreateCaseRisk,
      Loader,
      modalConfirm,
      CreateCategoryRisk,
        
      // RecursiveStatusList,
    },
    props: {
    },
    data() {
        return {
            toggle: {
                createCaseRisk: false,
                createEntityRisk: false,
                createCategortRisk: false,
            },
            colorOptions:[],
            headers: [
                {
                    field: "category",
                    customClass: "w-4/12 pl-2",
                },
                {
                    field: "description",
                    customClass: "w-4/12",
                },
                {
                    field: "score_range",
                    customClass: "w-3/12",
                },
                {
                    field: "action",
                    customClass: "w-1/12  justify-center",
                },
            ],
            dragOptionForList: {
                animation: 0,
                group: "description",
                disabled: true,
                ghostClass: "ghost",
            },
            riskEntityLabelList: [],
            riskCaseLabelList: [],
            riskCategoryLabelList: [],
            listLoading: false,
            viewOnlyMode: false,
            riskEntityLabelDetail: null,
            riskCaseLabelDetail: null,
            riskCategoryLabelDetail: null,
            caseMode: "",
            entityMode: "",
            categoryMode: "",
            mode:'',
            selectedRowId: "",
            isEdit:false,
        };
    },
    computed: {
        viewMode() {
            return this.$route.params.action === "view" ? true : !this.checkPermission('riskAssessment.edit');
        },
        getRiskAssessmentId() {
            return this.$route.params?.id;
        },
        getDisableState() {
            if (this.$route.params.action === "edit") {
                if (!this.riskEntityLabelList.length && !this.riskCaseLabelList.length && !this.riskCategoryLabelList.length) {
                    return true;
                } else {  
                    return this.$store.getters.getDisabledDone;
                }
            } else {
                if (this.riskEntityLabelList.length || this.riskCaseLabelList.length || this.riskCategoryLabelList.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        dragOptions() {
            return this.dragOptionForList;
        },
        computedHeaders() {
            return this.headers.map((head) => ({
                ...head,
                label: this.$t(`risk_assessment.label_headers.${head.field}`)
            }))
        }
    },
    async mounted() {
        await this.$store.dispatch("categoryList");
        await this.fetchRiskLabelList();
        await this.$store.dispatch("fetchRiskColors");
        if (this.viewMode) {
            this.dragOptionForList.disabled = true;
        }
    },
    methods: {
        checkPermission,
        handleCreateCase(value) {
            if(value === 'Case')
            {
                this.toggle.createCaseRisk = true;
                this.riskCaseLabelDetail = {
                    name: "",
                    colour: "",
                    colour_id: "",
                    description: "",
                    min_label_score: "",
                    max_label_score: "",
                    id: "",
                    label_type: ""
                }
                this.caseMode= ""
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
            }
            else if(value === 'Entity'){
                this.toggle.createEntityRisk = true;
                this.riskEntityLabelDetail = {
                    name: "",
                    colour: "",
                    colour_id: "",
                    description: "",
                    min_label_score: "",
                    max_label_score: "",
                    id: "",
                    label_type: ""
                }
                this.entityMode= ""
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
            }
            else if(value === 'risk_category'){
                this.toggle.createCategortRisk = true;
                this.riskCategoryLabelDetail = {
                    name: "",
                    colour: "",
                    colour_id: "",
                    description: "",
                    min_label_score: "",
                    max_label_score: "",
                    id: "",
                    label_type: ""
                }
                this.categoryMode= ""
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
            }
            else{
                this.toggle.createCaseRisk = false;
                this.toggle.createEntityRisk = false;
                this.toggle.createCategortRisk = false;
                this.$store.commit("SET_DISABLED_DONE", false);
            }
        },
        closeCreateCaseRisk(value) {
            if(value === 'Case')
            {
                this.toggle.createCaseRisk = false;
                this.isEdit = false;
                this.$store.commit("SET_DISABLED_DONE", false);
            }
            else if(value === 'Entity'){
                this.toggle.createEntityRisk = false;
                this.isEdit = false;
                this.$store.commit("SET_DISABLED_DONE", false);
            }
            else if(value === 'risk_category'){
                this.toggle.createCategortRisk = false;
                this.isEdit = false;
                this.$store.commit("SET_DISABLED_DONE", false);
            }
            this.selectedRowId= "";
        },
        async submitStatus(form) {
            if(form)
            {
                try { 
                    this.mode = this.caseMode || this.entityMode || this.categoryMode
                    const response = this.mode !== 'edit' ? await createRiskLabel(this.$route.params?.id, form) : await updateRiskLabel(this.$route.params?.id, this.riskLabelDetail, form);
                    if (response.status) {
                        this.mode !== 'edit' ? this.$toast.success(response?.data?.status_message || this.$t('general.create_success')) : this.$toast.success(response?.data?.status_message || this.$t('general.update_success'));
                        await this.fetchRiskLabelList();
                        await this.$store.dispatch("fetchRiskColors");
                    }
                } catch (err) {
                    console.error("err", err);
                    this.$toast.error(
                        err.response?.data?.detail || err.response?.data?.detail[0].msg || this.$t("general.default_error")
                    );
                    this.createStatusLoading = false;
                }
            }
        },
        editStatus(el) {
            if(el.label_type == "Case")
            {
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
                this.selectedRowId = el.name;
                this.caseMode = "edit";
                this.riskCaseLabelDetail = {
                    name: el.name,
                    colour: el.color,
                    colour_id: el.color,
                    description: el.description,
                    min_label_score: el.min_label_score,
                    max_label_score: el.max_label_score,
                    id: el.id,
                    label_type: el.label_type
                }
                this.toggle.createCaseRisk = true;

            }
            else if(el.label_type == "Entity")
            {
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
                this.selectedRowId = el.name;
                this.entityMode = "edit";
                this.riskEntityLabelDetail = {
                    name: el.name,
                    colour: el.color,
                    colour_id: el.color,
                    description: el.description,
                    min_label_score: el.min_label_score,
                    max_label_score: el.max_label_score,
                    id: el.id,
                    label_type: el.label_type
                }
                this.toggle.createEntityRisk = true;
            }
            else if(el.label_type == "risk_category")
            {
                this.isEdit = true;
                this.$store.commit("SET_DISABLED_DONE", true);
                this.selectedRowId = el.name;
                this.categoryMode = "edit";
                this.riskCategoryLabelDetail = {
                    name: el.name,
                    colour: el.color,
                    colour_id: el.color,
                    description: el.description,
                    min_label_score: el.min_label_score,
                    max_label_score: el.max_label_score,
                    id: el.id,
                    label_type: el.label_type
                }
                this.toggle.createCategortRisk = true;
            }
            else{
                this.caseMode= ""
                this.entityMode= ""
                this.categoryMode= ""
                this.isEdit = false;
            }
            this.riskLabelDetail = el.id
            
            if (this.viewMode) return;
        },
        async fetchRiskLabelList() {
            try {
                this.listLoading = true;
                const response = await getRiskLabel(this.$route.params?.id);
                this.riskEntityLabelList = (response?.data.filter((el) => el?.label_type == "Entity") || [])
                    .sort((a, b) => {
                        return a.name.localeCompare(b.name); 
                    });
                this.riskCaseLabelList = (response?.data.filter((el) => el?.label_type == "Case") || [])
                    .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });
                this.riskCategoryLabelList = (response?.data.filter((el) => el?.label_type == "risk_category") || [])
                    .sort((a, b) => {
                            return a.name.localeCompare(b.name); 
                        });
                this.$store.commit("SET_CATEGORY_LIST", response?.data);
                this.caseMode= "";
                this.entityMode= "";
                this.categoryMode= "";
                this.listLoading = false;
            } catch (err) {
                this.listLoading = false;
                console.log("err", err);
            }
            },

        handleSubmit() {
            if (this.viewMode) return;
            if(this.$route.params.action == 'edit'){
                this.$toast.success(this.$t('risk_assessment.update_risk_assessment_success'));
            }
            else if(this.$route.params.action == "create"){
                this.$toast.success(this.$t('risk_assessment.create_risk_assessment_success'));
            }
            this.$emit("lockAssessment");
            this.$router.push({
                name: "Risk Assessment",
                path: "/risk-assessment"
            });
            
        },
        async deleteRiskLable(el) {
            if (this.viewMode) return;
            const promise = await this.$refs["confirm-popup"].show({
                title: this.$t('risk_assessment.confirm_modal_title'),
                message: this.$t('risk_assessment.delete_label_confirmation'),
            });
            if (promise) {
                try {
                    let payload = {
                        risk_assessment_id: el.risk_assessment_id,
                        risk_label_id: el.id,
                    };
                    const response = await deleteRiskLabel(payload);
                    if (response.status) {
                        this.$toast.success(response?.data?.status_message || this.$t('general.delete_success'));
                        await this.fetchRiskLabelList();
                        await this.$store.dispatch("fetchRiskColors");
                    }
                } catch (err) {
                    console.error("err", err);
                    this.$toast.error(err?.response?.data?.status_message || this.$t("general.default_error"));
                }
            }
            this.$refs["confirm-popup"].close();
        },
    }
      
  };
  </script>
  