<template>
    <div>
      <div class="py-1">
        <label class=""
          >{{ label }}<span v-if="required" class="text-red-600 px-1">*</span></label
        >
      </div>
  
      <div
        class="select-box relative w-full px-1 h-9 flex rounded-md bg-white"
        @click="toggleDropdown"
        :disabled="!editable"
        :class="editable ? '': 'disabled-dropdown'"
        ref="customSelect"
      >
        <div class="flex items-center cursor-pointer w-full justify-between select-box-content">
          <div class="flex">
            <span
            class="w-5 h-5 border-1 border-gray-200 rounded-full"
            :style="`background-color: ${selectedOption.colour}`"
          ></span>
          <span
            class="px-2 text-sm"
            :class="selectedOption.colour ? `` : `text-gray-400`"
          >
            {{ selectedOption.colour || placeholder }}
          </span>
          </div>
          <div class="flex">
            <span class="icon text-base ml-auto mr-2 w-4  transition-transform transform ease-out duration-300"
              :class="isOpen? ' rotate-180' : 'rotate-0'">
              <font-awesome-icon icon="chevron-down" />
            </span>
          </div>
        </div>
        <div
          class="dropdown border-1 w-38 border-gray-300 rounded-lg bg-white p-2"
          v-show="isOpen"
        >
          <div class="grid md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 items-center gap-1.5">
            <div
              v-for="(option, idx) in colorOptions"
              :key="idx"
              class="flex items-center justify-center"
              @click="selectOption(option)"
            >
              <div
                class="h-6 w-6 rounded-sm flex justify-center items-center"
                :class="option.disabled ? `` : `cursor-pointer`"
                :style="`background-color: ${option.colour}`"
              >
                <!-- check tick -->
                <span
                  class="flex justify-center items-center z-99 w-full h-full"
                  v-if="!option.disabled && option.colour === selectedOption.colour"
                >
                  <svg
                    class="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="white"
                  >
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                    />
                  </svg>
                </span>
                <!-- x-mark -->
                <span
                  class="flex justify-center items-center z-99 w-full h-full"
                  v-if="option.disabled"
                >
                  <svg
                    class="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    fill="white"
                  >
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showError">
        <span class="text-red-800 text-sm"> {{ $t('risk_assessment.color_required') }} </span>
      </div>
    </div>
  </template>
  
  <script>
  // import vue from "vue";
  export default {
    name: "risk-color-picker",
    props: {
      colorOptions: {
        type: Array,
        required: false,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: "",
      },
      label: {
        required: false,
        default: "",
      },
      required: {
        type: Boolean,
        default: false,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      statusDetail: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        isOpen: false,
        selectedOption: {id: "", colour: "", disabled: false },
        showError: false,
      };
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
      if (this.statusDetail) {
        this.selectedOption = { id: this.statusDetail.colour_id, colour:  this.statusDetail.colour, disabled: false}
        this.$store.commit("SET_RISK_COLOR", this.selectedOption);
      }
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    },
    watch: {
      statusDetail(newVal) {
        this.selectedOption = { id: newVal.colour_id, colour:  newVal.colour, disabled: false}
        this.$store.commit("SET_RISK_COLOR",  this.selectedOption);
      },
      selectedOption(newVal) {
        let flag= !!newVal.colour;
        this.$store.commit("SET_RISK_COLOR_VALIDATION_ERROR", flag);
      }
    },
    methods: {
      handleClickOutside(event) {
        if (this.$refs?.customSelect && !this.$refs?.customSelect?.contains(event.target)) {
          this.isOpen = false;
          // this.showError = false;
        } 
        
      },
      toggleDropdown() {
      
        this.isOpen = !this.isOpen;
        
      },
      selectOption(option) {
        if (!option.disabled) {
          this.selectedOption = option;
          // if(this.selectOption.colour === "") {
          //     this.selectedOption = option;
          //     this.showError = false;
          // } 
          // else if( this.selectedOption.colour === option.colour) {
          //     this.selectedOption = {colour: "", disabled: false}
          //     this.showError = true;
          // } 
          // else {
          //     this.selectedOption = option;
          //     this.showError = false;
          // }
          this.$emit("input", option);
        }
      },
    },
  };
  </script>
  <style scoped>
  .custom-select {
    position: relative;
    width: 200px;
  }
  
  .select-box {
    /* display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px; */
    border: 1px solid #cecece;
    /* cursor: pointer; */
  }
  
  .arrow {
    margin-left: 10px;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #cecece;
    background-color: white;
    z-index: 10;
  }
  
  .disabled-dropdown {
      pointer-events: none;
      opacity: 0.5;
      /* Optional: Add additional styles to convey disabled state */
      background-color: #f2f2f2;
      color: #999;
      cursor: not-allowed;
  }
  </style>
  