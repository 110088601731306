import { render, staticRenderFns } from "./create-entity-risk.vue?vue&type=template&id=66b1193b"
import script from "./create-entity-risk.vue?vue&type=script&lang=js"
export * from "./create-entity-risk.vue?vue&type=script&lang=js"
import style0 from "./create-entity-risk.vue?vue&type=style&index=0&id=66b1193b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports