<template>
  <div class="flex flex-col w-full justify-between flex-grow max-h-full overflow-y-auto">
      <sub-header
        :showBreadCrumbs="true"
        :customCrumbLists="customBreadCumbs"
        :showBtn="showCreateButton"
        :buttonText="$t('risk_assessment.add_new')"
        :handleClick="addAssessment"
        :backButton="true"
        class="mb-4"
        backRedirectionUrl="/risk-assessment"
        :setEditModeUnlock="lockAssessment"
      />

      <div class="flex h-full gap-4 flex-1 overflow-y-auto">
        <div class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar">
          <div
            @click="handleCurrentTab(nav)"
            v-for="(nav, idx) in computedNavigation"
            :key="idx"
            class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3"
            :class="{
              'bg-primary-300 text-white-text': currentTab === nav.nav,
              'hover:bg-gray-100 hover:text-base-content-600': currentTab !== nav.nav,
              'border-t border-gray-400 border-0': idx != 0,
              'cursor-pointer': getRiskId || nav.nav === 'details',
              'cursor-not-allowed': !getRiskId && nav.nav !== 'details' 
            }"
          >
            <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
            {{ nav.title }}
          </div>
        </div>
        <div v-if="currentTab"
          class="flex-1 relative rounded-md h-max min-h-full overflow-y-auto scroll-bar">
          <div v-if="currentTab !== 'assessment' && getTabLabel"
            class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium"
            :style="`max-height: 20vh; border-radius: 0.375rem 0.375rem 0 0;`"
          >
            <span class="font-bold">{{ getTabLabel }} </span>
          </div>

          <div class="flex pb-4 w-full h-full">
            <component
              :ref="currentTab"
              v-bind:is="currentComponent"
              :riskData="riskData"
              @saveAndNext="handleSaveAndNext"
              @lockAssessment="lockAssessment"
            >
            </component>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Empty from "@shared/empty";
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import SubHeader from "@/components/SubHeader";
import Details from "./components/detail";
import Category from "./components/category";
import Assessment from "./components/assessment";
import Label from "./components/label";
import {} from "./service.js";
import axios from "@/axios";
import { FormatDateNew } from "@/plugins/functions";
import {checkPermission} from "@shared/utils/functions"

export default {
  name: "Risk Assessment Details",
  title: "Risk Assessment Details",
  data() {
    return {
      RiskId: "",
      customBreadCumbs: [],
      currentTab: "details",
      addDefault: false,
      riskData: null,
      screeningMenuWrapper: {
        details: Details,
        category: Category,
        assessment: Assessment,
        label: Label,
      },
      navigation: [
        {
          nav: "details",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
        },
        {
          nav: "category",
          icon: "table",
          subnav: false,
        },
        {
          nav: "assessment",
          icon: "list",
          subnav: false,
        },
        {
          nav: "label",
          icon: "tag",
          subnav: false,
        },
      ],
      riskAssessmentId: "",
    };
  },
  components: {
    Empty,
    "v-button": Button,
    Loader,
    SubHeader,
    Details,
    Category,
    Assessment,
    Label,
  },
  computed: {
    currentComponent() {
      return this.screeningMenuWrapper[this.$route.query.tab];
    },
    getTabLabel() {
      return this.computedNavigation.find((nav) => nav.nav === this.currentTab).label;
    },
    getRiskId() {
      return this.$route.params?.id;
    },
    isViewMode() {
      return this.$route.params.action == "view" ? true : false;
    },
    isCreatePermission(){
      return checkPermission('riskAssessment.edit')
    },
    showCreateButton() {
        if(this.currentTab === 'assessment') {
          return this.isViewMode ? false : this.isCreatePermission
        } 
        return false;
    },
    computedNavigation() {
      return this.navigation.map((nav) => ({
        ...nav,
        title:  this.$t(`risk_assessment.navigation_title.${nav.nav}`),
        label:  this.$t(`risk_assessment.navigation_labels.${nav.nav}`)
      }))
    }
  },
  async mounted() {
    this.currentTab = this.$route.query.tab;
    this.setBredCrumbs(this.currentTab);
    
    if (this.getRiskId) {
      this.getRiskData();
      this.lockAssessment(true);
    }

  },
  methods: {
    checkPermission,
    async getRiskData() {
      this.isLoading = true;
      this.riskData = null;
      let url = `/risk-assessment?config_id=${this.getRiskId}`;
      let { data } = await axios.get(url);
      try {
        // const formatDate = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
        let risksData = data?.data?.map((el) => {
          if (el.created_at || el.modified_at) {
            el.created_at = FormatDateNew(new Date(el.created_at));
            el.modified_at = FormatDateNew(new Date(el.modified_at));
            return el;
          }
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        });
        this.riskData = risksData?.[0];
      } catch (error) {
        console.log(error, "Error");
      }
    },

    async lockAssessment(status) {
      if (this.isViewMode) return;
      try {
        await axios.post(`/risk-assessment/locked/${this.getRiskId}`, {
          lock_status: status || false,
        });
        // this.$toast.success(data.message || );
      } catch (error) {
        console.log(error);
        // this.$toast.error(
        //   error.response.data.detail || "Unable to create assessment"
        // );
      }
    },

    handleSaveAndNext(payload) {
      this.riskAssessmentId = payload.id; 
      let currentTabIndex = this.computedNavigation.findIndex((nav) => nav.nav === payload.from);
      this.handleCurrentTab(this.computedNavigation[currentTabIndex + 1]);
    },

    handleCurrentTab(tab) {
      if (this.getRiskId || this.riskAssessmentId || tab.nav === 'details') {
        this.$router.push({
          query: { tab: tab.nav },
          params: {id: this.riskAssessmentId || this.getRiskId, action: this.$route.params.action}
        });
        
        if (this.currentTab === tab.nav) return;
        this.currentTab = tab.nav;
        this.setBredCrumbs(this.currentTab);
      }
    },

    setBredCrumbs() {
      this.customBreadCumbs = [
          { name: this.currentTab },
      ];
    },

    addAssessment() {
      this.$refs["assessment"]?.addAssessment();
    }
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
