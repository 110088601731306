var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full justify-between flex-grow max-h-full overflow-y-auto"},[_c('sub-header',{staticClass:"mb-4",attrs:{"showBreadCrumbs":true,"customCrumbLists":_vm.customBreadCumbs,"showBtn":_vm.showCreateButton,"buttonText":_vm.$t('risk_assessment.add_new'),"handleClick":_vm.addAssessment,"backButton":true,"backRedirectionUrl":"/risk-assessment","setEditModeUnlock":_vm.lockAssessment}}),_c('div',{staticClass:"flex h-full gap-4 flex-1 overflow-y-auto"},[_c('div',{staticClass:"w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"},_vm._l((_vm.computedNavigation),function(nav,idx){return _c('div',{key:idx,staticClass:"px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3",class:{
            'bg-primary-300 text-white-text': _vm.currentTab === nav.nav,
            'hover:bg-gray-100 hover:text-base-content-600': _vm.currentTab !== nav.nav,
            'border-t border-gray-400 border-0': idx != 0,
            'cursor-pointer': _vm.getRiskId || nav.nav === 'details',
            'cursor-not-allowed': !_vm.getRiskId && nav.nav !== 'details' 
          },on:{"click":function($event){return _vm.handleCurrentTab(nav)}}},[_c('font-awesome-icon',{attrs:{"icon":[nav.iconPrefix || 'fa', nav.icon]}}),_vm._v(" "+_vm._s(nav.title)+" ")],1)}),0),(_vm.currentTab)?_c('div',{staticClass:"flex-1 relative rounded-md h-max min-h-full overflow-y-auto scroll-bar"},[(_vm.currentTab !== 'assessment' && _vm.getTabLabel)?_c('div',{staticClass:"px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium",style:(`max-height: 20vh; border-radius: 0.375rem 0.375rem 0 0;`)},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.getTabLabel)+" ")])]):_vm._e(),_c('div',{staticClass:"flex pb-4 w-full h-full"},[_c(_vm.currentComponent,{ref:_vm.currentTab,tag:"component",attrs:{"riskData":_vm.riskData},on:{"saveAndNext":_vm.handleSaveAndNext,"lockAssessment":_vm.lockAssessment}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }