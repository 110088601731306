<template>
  <div class="flex-1 flex flex-col max-h-full">
    <sub-header
        :showBreadCrumbs="true"
        :customCrumbLists="customBreadCumbs"
        :showBtn="false"
        :backButton="true"
        class="mb-4"
        :backRedirectionUrl="`/risk-assessment/${$route.params.action}/${$route.params.id}?tab=assessment`"
    />

    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"
      v-if="getAssessmentData"
    >
        <UISection
            class="overflow-y-hidden"
        >
            <template #title v-if="getAssessmentData.type === 'Field'">
                <span class="text-white-text"> {{ getAssessmentData.field_name }}</span>
                <!-- <span class="font-normal text-white-text"> </span> -->
            </template>

            <template #title v-else-if="getAssessmentData.type === 'Check'">
                <span class="text-white-text"> {{ getAssessmentData.tenant_check_name }}</span>
                <!-- <span class="font-normal text-white-text"> </span> -->
            </template>
        
            <div
                class="w-full scroll-bar"
                style="height: 40rem;"
            >
                <UISection v-if="getAssessmentData.type === 'Field'"
                    class="overflow-y-hidden custom-ui-section"
                >

                    <template #title>
                        <span class="text-black-text"> 
                            {{ $t('risk_assessment.options') }} 
                            <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                        </span>
                    </template>

                    <div class="mx-auto flex flex-col gap-5 justify-center items-center w-full h-80 rounded-lg ring-2-gray-500" v-if="optionsLoading">
                        <Loader />
                    </div>
                
                    <div v-else
                        class="w-full scroll-bar"
                        style="max-height: calc(100% - 56px)"
                    >
                        <List 
                            activeKey="active"
                            :showTitle="false" 
                            :listItems="filteredFieldOptions" :showSelectAll="true" 
                            :disableCheckbox="isViewMode || isAssessmentViewMode" 
                            :showSearch="true" 
                            @handleSelect="selectAllOptions($event)" 
                            @handleFilterResult="handleFilterResult($event)"
                        >
                            <template #listheader>
                                <!-- <div class="px-4 py-1 grid text-gray-600 text-sm font-semibold" :class="`grid-cols-${headerList.length} ${headerList.length > 1 && 'tenant_element' }` " >
                                    <span v-for="header in headerList" :key="header.name"> {{header.name}}</span>
                                </div> -->
                            </template>
                            <template #item="{ item }">
                                <div class="items-center w-full px-2">
                                    <div class="flex gap-4 items-center w-full">
                                        <input :disabled="isViewMode || isAssessmentViewMode || !item.active" 
                                            @change="handleSelect($event, item)" 
                                            v-model="item.checked" type="checkbox" 
                                            :id="removeSpaces(`${item.option_label}__${item.option_value}`)" 
                                            class="checkbox cursor-pointer checkbox-primary checkbox-sm flex" />

                                        <neo-input
                                            :placeholder="$t('risk_assessment.option_label')"
                                            v-model.trim="item.option_label"
                                            disabled
                                            class="rounded flex-grow h-6"
                                            inputClass="w-full"
                                        >
                                        </neo-input>

                                        <neo-input
                                            :placeholder="$t('risk_assessment.option_value')"
                                            v-model.trim="item.option_value"
                                            bg="white"
                                            disabled
                                            class="rounded flex-grow h-6"
                                            inputClass="w-full"
                                        >
                                        </neo-input>
                                    </div>
                                </div>
                            </template>
                        </List>
                    </div>
                </UISection>

                <UISection v-else-if="getAssessmentData.type === 'Check'"
                    class="overflow-y-hidden custom-ui-section"
                >

                    <template #title>
                        <span class="text-black-text"> 
                            {{ $t('risk_assessment.check_inputs_title') }} 
                        </span>
                    </template>
                
                    <div
                        class="w-full scroll-bar"
                        style="max-height: calc(100% - 56px);"
                    >
                        <FormulateForm class="min-h-full flex-1">
                            <div class="add-check__modal-content h-full overflow-hidden">
                                <div class="form flex w-full flex flex-1 gap-4 overflow-auto scroll-bar pr-1">
                                    <FormulateInput
                                        v-model="assessmentData.count"
                                        :disabled="isViewMode || isAssessmentViewMode"
                                        validation="required|number|min:1|max:100"
                                        class="w-full"
                                        :label="$t('risk_assessment.count_label')"
                                        :placeholder="$t('risk_assessment.count_placeholder')"
                                        type="number"
                                        variant="alt"
                                        :element-class="
                                        (context, classes) => ['flex-1 min-w-full'].concat(classes)
                                        "
                                    />

                                    <FormulateInput
                                        v-model="assessmentData.status"
                                        :disabled="isViewMode || isAssessmentViewMode"
                                        validation="required"
                                        class="w-full"
                                        :label="$t('risk_assessment.status_label')"
                                        :placeholder="$t('risk_assessment.status_placeholder')"
                                        type="multi-select"
                                        variant="alt"
                                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                        :options="computedStatusOptions"
                                        :config="{ label: 'value', trackBy: 'value' }"
                                    />

                                    <FormulateInput
                                        v-model="assessmentData.risk_tag"
                                        :disabled="isViewMode || isAssessmentViewMode"
                                        class="w-full"
                                        :label="$t('risk_assessment.risk_label')"
                                        :placeholder="$t('risk_assessment.risk_placeholder')"
                                        type="multi-select"
                                        variant="alt"
                                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                        :options="riskTagOptions"
                                        :config="{ label: 'category', trackBy: 'category' }"
                                    />
                                </div>
                            </div>
                        </FormulateForm>
                    </div>
                </UISection>
            </div>
        </UISection>


        <UISection
            class="overflow-y-hidden mt-4"
        >

            <template #title>
                <span class="text-white-text"> {{ $t('risk_assessment.score') }}  </span>
            </template>
        
            <div
                class="w-full scroll-bar"
                style="height: 40rem;"
            >
                 <UISection
                    class="overflow-y-hidden custom-ui-section"
                >

                    <template #title>
                        <span class="text-black-text"> {{ $t('risk_assessment.score_title') }} </span>
                    </template>
                
                    <div
                        class="w-full scroll-bar"
                        style="max-height: calc(100% - 56px)"
                    >
                        <FormulateForm class="min-h-full flex-1">
                            <div class="add-check__modal-content h-full overflow-hidden">
                                <div class="form flex w-full flex-col flex-1 overflow-auto scroll-bar pr-1">
                                    <FormulateInput
                                        v-model="assessmentData.score"
                                        :disabled="isViewMode || isAssessmentViewMode"
                                        validation="required|number|min:0|max:100"
                                        class="w-full"
                                        :label="$t('risk_assessment.score_label')"
                                        :placeholder="$t('risk_assessment.score_placeholder')"
                                        type="number"
                                        variant="alt"
                                        :element-class="
                                        (context, classes) => ['flex-1 min-w-full'].concat(classes)
                                        "
                                    />
                                </div>
                            </div>
                        </FormulateForm>
                    </div>
                </UISection>
            </div>
        </UISection>

        <div class="flex justify-end pt-4" v-if="!isAssessmentViewMode">
            <div class="">
                <v-button
                    :text="$t('actions.save')"
                    class="py-2"
                    type="primary"
                    :disabled="saveButtonDisabled"
                    @click="handleCreateAssessment"
                />
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import Loader from "@shared/loader";
import axios from "@/axios";
import mainAxios from "@shared/light-ray/axios";
import List from "@shared/components/lists";
// import TenantListItem from "@shared/components/license-pack/tenant-list-item";
import Button from "@/components/button";
import { mapActions, mapGetters, mapState } from "vuex";
import {cloneDeep} from "lodash";
import { FormatDateNew } from "@/plugins/functions";

export default {
  name: "risk-admin",
  title: "Risk Assessment",

  components: {
    SubHeader,
    "neo-input": Input,
    Loader,
    List,
    // TenantListItem,
    "v-button" :Button,
  },
  props: {
    riskData: {
      type: Object,
      default: () => {},
    }
  },
  data: () => ({
    assessmentData: {
      score: '',
      count: '',
      risk_tag: [],
      status: [],
    },
    statusOptions: [{
        key: "CONFIRMED",
    }, {
        key: "POTENTIAL",
    }, {
        key: "IRRELEVANT",
    }, {
        key: "UNKNOWN",
    }],
    riskTagOptions: [],
    fieldOptions: [],
    filteredFieldOptions: [],
    headerList: [{ name: "Title" }, { name: "Value" }],
    optionsLoading: false,
    getAssessmentData: null,
    customBreadCumbs: [],
  }),
  async created() {
  },
  computed: {
      ...mapGetters(["getCurrentAssessmentData"]),
    ...mapState({
      entityTypeOptions: "entityTypeList",
    }),
    getAssessmentId() {
      return this.$route.params?.assessmentId;
    },
    isAssessmentViewMode() {
        return this.$route.params.assessment_action == "view" ? true : false;
    },

    isViewMode() {
      return this.$route.params.action == "view" ? true : false;
    },

    saveButtonDisabled() {
        let payload;
        if (this.getAssessmentData?.type === "Check")  {
            payload = {
                count: parseInt(this.assessmentData?.count, 10),
                status: this.assessmentData?.status.map((status) => status.value),
                score: parseInt(this.assessmentData?.score, 10),
            };
            if (
                (!payload.score && payload.score !== 0) || !payload.count || 
                !payload.status.length
            ) return true;
        } else if (this.getAssessmentData?.type === "Field" ) {
            payload = {
                options: this.fieldOptions.filter((opt) => opt.checked).map((opt) => ({
                    option_value: opt.option_value,
                    option_label: opt.option_label,
                })),
                score: parseInt(this.assessmentData?.score, 10),
            };

            if (
                (!payload.score && payload.score !== 0) ||
                !payload.options.length
            ) return true;
        }
        return false;
    },

    computedStatusOptions() {
        return this.statusOptions.map((stat) => ({
            ...stat,
            value: this.$t(`general.status_options.${stat.key}`),
        }))
    }
  },

  async mounted() {
      this.setBredCrumbs();

      if (this.getAssessmentId) {
            await this.fetchRiskAssessmentList();
            if (this.getAssessmentData?.type === "Check")
                await this.getRiskCategoriesOptions();

            this.assessmentData = {
                count: this.getAssessmentData?.type === "Check" ? this.getAssessmentData.count : null,
                status: this.getAssessmentData?.type === "Check" ? this.getAssessmentData?.status.map((stat) => 
                    this.computedStatusOptions.find((status) => status.value === stat)
                ): null,
                risk_tag: this.getAssessmentData?.type === "Check" ? this.getAssessmentData?.risk_tag.map((risk) => 
                    this.riskTagOptions.find((rc) => rc.category === risk)
                ) : null,
                score: this.getAssessmentData.score ?? null,
            };
      } else if (this.getCurrentAssessmentData)
            this.getAssessmentData = this.getCurrentAssessmentData;

      if (this.getAssessmentData?.type) {
        if (this.getAssessmentData.type === 'Field') {
            await this.fetchRiskFieldOptions();
            this.handleFilterResult('');
            for (const index in this.getAssessmentData.options) {

                setTimeout(() => {
                    const option = this.getAssessmentData.options[index];
                    let input = document.querySelectorAll(`[id='${this.removeSpaces(option.option_label)}__${this.removeSpaces(option.option_value)}']`);
                    input[0].click();
                    input[0].checked = true;

                    const item = this.getAssessmentData.options[index];
                    this.fieldOptions = this.fieldOptions.map((opt) => {
                        if (opt.option_value === item.option_value)
                            return {
                                ...opt,
                                checked: true,
                            }
                        else return opt;
                    });
                    this.filteredFieldOptions = this.filteredFieldOptions.map((opt) => {
                        if (opt.option_value === item.option_value)
                            return {
                                ...opt,
                                checked: true,
                            }
                        else return opt;
                    })
                }, 100);
            }

            const disabledOptions = this.fieldOptions.filter((opt) => !opt.active);
            for (const index in disabledOptions) {
                setTimeout(() => {
                    const option = disabledOptions[index];
                    let input = document.querySelectorAll(`[id='${this.removeSpaces(option.option_label)}__${this.removeSpaces(option.option_value)}']`);
                    input[0].click();
                    input[0].checked = true;
                })
            }
        } else await this.getRiskCategoriesOptions();
      } else {
          this.$router.push({
              path: `/risk-assessment/${this.$route.params.action}/${this.$route.params.id}?tab=assessment`,
          });
      }
  },

  methods: {
    ...mapActions(["fetchEntityTypeList"]),

    removeSpaces(id) {
        return id?.split(" ").join("")?.replaceAll("'", "");
    },

    setBredCrumbs() {
        this.customBreadCumbs = [{ 
            name: this.getAssessmentId ? 
                this.$t('risk_assessment.assessment_details'): 
                this.$t('risk_assessment.create_assessment') 
        }];
    },

    async fetchRiskAssessmentList() {
      this.isLoading = true;
      let url = `/risk-assessment/${this.$route.params.id}/assessment?id=${this.getAssessmentId}`;
      let { data } = await axios.get(url);
      try {
        let risksData = data?.map((el) => {
          if (el.created_at || el.modified_at) {
            el.created_at = FormatDateNew(new Date(el.created_at));
            el.modified_at = FormatDateNew(new Date(el.modified_at));
            return el;
          }

          if (el.type === "Check") 
            return {...el, subtype: el.tenant_check_name };
          else if (el.type === "Field") 
            return {...el, subtype: el.field_name };
          
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        })?.[0];
        this.getAssessmentData = risksData;
    } catch (error) {
        console.log(error, "Error");
      }
      this.isLoading = false;
    },

    async getRiskCategoriesOptions() {
        try {   
            const url = `/service/risk-categories?tenant_check_id=${this.getAssessmentData.tenant_check_id}`
            let categories = await mainAxios.get(url);
            this.riskTagOptions = categories.data.risk_data;
        } catch (err) {
            console.log(err)
        }
    },

    async fetchRiskFieldOptions() {
      this.optionsLoading = true;
      let url = `/risk-assessment/field/options`;
      const payload =  {
          field_name: this.getAssessmentData.field_name,
          risk_category_id: this.getAssessmentData.risk_category_id,
          category_assessment_id: this.getAssessmentId,
      }
      let { data } = await axios.post(url, payload);
      try {
        this.fieldOptions = data.map((opt) => ({
            ...opt,
            id: this.removeSpaces(`${opt.option_label}__${opt.option_value}`),
        }));
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.optionsLoading = false;
    },

    handleFilterResult(event) {
        let list = [];
        list = cloneDeep(this.fieldOptions);
        this.filteredFieldOptions = cloneDeep(list);

        list = list.filter((opt) => opt.option_label?.toLowerCase()?.includes(event?.toLowerCase()) || opt.option_value?.toLowerCase()?.includes(event?.toLowerCase()));
        this.filteredFieldOptions = cloneDeep(list);
    },

    selectAllOptions(event) {
        if (this.isViewMode || this.isAssessmentViewMode) return;
        this.fieldOptions = this.fieldOptions.map((opt) => ({ 
            ...opt,
            checked: opt?.active ? event: opt.checked, 
        }));
        this.filteredFieldOptions = this.filteredFieldOptions.map((opt) => ({ 
            ...opt,
            checked: opt?.active ? event: opt.checked, 
        }));
    },

    handleSelect(event, item) {
        this.fieldOptions = this.fieldOptions.map((opt) => {
            if (opt.option_value === item.option_value)
                return {
                    ...opt,
                    checked: event?.target?.checked,
                }
            else return opt;
        });
        this.filteredFieldOptions = this.filteredFieldOptions.map((opt) => {
            if (opt.option_value === item.option_value)
                return {
                    ...opt,
                    checked: event?.target?.checked,
                }
            else return opt;
        })
    },

    async handleCreateAssessment() {
        let payload;

        if (this.getAssessmentData?.type === "Check")  {
            payload = {
                ...this.getAssessmentData,
                count: parseInt(this.assessmentData?.count, 10),
                status: this.assessmentData?.status.map((status) => status.value),
                risk_tag: this.assessmentData?.risk_tag.map((risk) => risk.category),
                options: null,
                score: parseInt(this.assessmentData?.score, 10),
            };
            if (
                (!payload.score && payload.score !== 0) || !payload.count || 
                !payload.status.length
            ) {
                this.$toast.error(this.$t("general.fill_required_fields_error"));
                return;
            } else if (
                payload.score > 100 || payload.score < 0 || 
                payload.count > 100 || payload.count < 1
            ) {
                this.$toast.error(this.$t("general.invalid_inputs_error"));
                return;
            }
        } else if (this.getAssessmentData?.type === "Field" ) {
            payload = {
                ...this.getAssessmentData,
                count: null,
                status: null,
                risk_tag: null,
                options: this.fieldOptions.filter((opt) => opt.checked).map((opt) => ({
                    option_value: opt.option_value,
                    option_label: opt.option_label,
                })),
                score: parseInt(this.assessmentData?.score, 10),
            };

            if (
                (!payload.score && payload.score !== 0) ||
                !payload.options.length
            ) {
                this.$toast.error(this.$t("general.fill_required_fields_error"));
                return;
            } else if (
                payload.score > 100 || payload.score < 0
            ) {
                this.$toast.error(this.$t("general.invalid_inputs_error"));
                return;
            }
        }
        try {
            if (this.getAssessmentId) {
                let url = `/risk-assessment/${this.$route.params.id}/assessment/${this.getAssessmentId}`;
                let { data } = await axios.put(url, payload);
                this.$toast.success(data.message || this.$t('risk_assessment.update_assessment_success', { type : payload.type }));
            } else {
                let url = `/risk-assessment/${this.$route.params.id}/assessment`;
                let { data } = await axios.post(url, payload);
                this.$toast.success(data.message || this.$t('risk_assessment.create_assessment_success', { type : payload.type }));
            }
            this.$router.push({
                path: `/risk-assessment/${this.$route.params.action}/${this.$route.params.id}?tab=assessment`,
            });
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || this.$t('risk_assessment.create_assessment_failure'));
        }
    },
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px;
}

.hover-text {
  position: relative;
}
.custom-ui-section {
    .card-base__title {
        background: var(--theme-color-primary-100)!important;
    }
}
</style>
